@use "../react-components/styles/theme.scss";

:local(.dialog-overlay) {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    background-color: rgba(156, 156, 156, 0.5);
    font-size: 18px;
}

:local(.dialog) {
    width: 80vw;
    height: 80vh;
    background-color: white;
    border-radius: 16px;

    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    overflow: auto;

    @media(max-width: theme.$breakpoint-sm) {
        width: 93vw;
        height: 88vh;
    }
}

:local(.dialog-content) {
    margin: 40px 40px;
}

:local(.dialog-title) {
    color: theme.$primary-color;
    font-size: 24px;
    margin: 40px 0 10px 0;
    width: 100%;
}

:local(.dialog-section) {
    margin: 5px 60px;
    @media(max-width: theme.$breakpoint-sm) {
        margin: 5px 5px;
    }
}
:local(.section-Heading) {
    color: theme.$primary-color;
    font-weight: 700;
    margin: 40px 0 10px 0;
}
:local(.section-content) {
    font-size: 18px;
    margin: 5px 0px;
    @media(max-width: theme.$breakpoint-sm) {
        font-size: 16px;
        margin: 5px 0px;
    }
}
:local(.text-input-field) {
    margin: 10px 0;
}

:local(.buttons) {
    width: 100%;
    margin: 5px 0;
}
:local(.optionsButtons) {
    margin-top: 20px;
    display: inline-block;
    margin: 10px 10px 0 0;
    max-width: 20px;
    @media(min-width: theme.$breakpoint-lg) {
        min-width: 210px;
    }
}

:local(.submit-buttons) {
    margin: 40px 0 0 0px;
}
:local(.submit-button) {
    margin: 5px 5px;
    display: inline-block;
}

.active-button {
    background-color: theme.$accent5-color;
}

:local(.dialog-result) {
    width: 80%;
    display: none;
    border-radius: 16px;
    border: 2px solid theme.$primary-color;
    background-color: white;

    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    @media(max-width: theme.$breakpoint-sm) {
        width: 88vw;
        height: 84vh;
        font-size: 16px;
    }
}
:local(.dialog-result-content) {
    margin: 40px;
}