@use "../styles/theme.scss";

:local(.home-page) {
  flex: 1;
  background-size: cover;
  padding: 0;
  flex-direction: column;
  align-items: center;

  @media (min-width: theme.$breakpoint-lg) {
    align-items: center;
    justify-content: flex-start;
  }

  & > section {
    margin: 2em auto;
  }
}

:local(.logo-container) {
  display: flex;
  justify-content: center;
  padding: 20px;
  width: 300px;
  
  img {
    width: 100%;
    align-self: flex-start;
  }

  @media (min-width: theme.$breakpoint-lg) {
    display: none;
  }
}

:local(.sign-in-container) {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  @media(min-width: theme.$breakpoint-lg) {
    display: none;
  }
}

:local(.mobile-sign-out) {
  padding: 0.5rem;
}

:local(.hero) {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  @media (min-width: theme.$breakpoint-lg) {
    flex-direction: row;
  }
}

:local(.hero-image-container) {

  img {
    @media (min-width: theme.$breakpoint-lg) {
      border-radius: 16px;
    }
  }
}

:local(.app-info) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

  & > * {
    margin-bottom: 20px;
  }
  
  @media (min-width: theme.$breakpoint-lg) {
    margin-bottom: 0;
    margin-left: 16px;
    justify-content: flex-start;
    align-items: flex-start;

    & > :last-child {
      margin-bottom: 0;
    }
  }
}

:local(.app-description) {
  white-space: pre-wrap;
  align-self: auto;
  font-size: theme.$font-size-lg;
  font-weight: theme.$font-weight-medium;
  text-align: center;
  margin: 0 24px 20px 24px;
  line-height: 1.25;

  @media (min-width: theme.$breakpoint-lg) {
    text-align: left;
    margin: 0 16px 48px 0px;
    max-width: 860px;
    font-size: theme.$font-size-2xl;
  }
}

:local(.center-logo) {
  align-self: auto;
  margin: 24px 0;
}

:local(.cta-buttons) {
  display: flex;
  flex-direction: column;
  align-items: center;
}

:local(.features) {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  
  & > * {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }

    @media(min-width: theme.$breakpoint-lg) {
      margin-right: 24px;
      margin-bottom: 0;
      flex-direction: row;
      max-width: 364px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
  
  img {
    width: 100%;
    border-radius: 12px;
  }
}

:local(.rooms-container) {
  margin-top: 120px !important;
  display: flex;
  flex-direction: column;
}

:local(.rooms-titles) {
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
  margin: 0 !important;
}
:local(.rooms-heading) {
  margin: 16px 40px;
  font-size: 24px;
  margin-bottom: 16px;
  cursor: pointer;
  color: theme.$primary-color;
  border-bottom: 2px solid theme.$primary-color;
  @media(max-width: theme.$breakpoint-sm) {
    font-size: 18px;
  }
  &:hover {
    color: theme.$primary-color-hover;
  }
}
:local(.rooms-heading-unactive) {
  color: gray;
  margin: 16px 40px;
  font-size: 24px;
  margin-bottom: 16px;
  cursor: pointer;
  @media(max-width: theme.$breakpoint-sm) {
    font-size: 18px;
  }
  &:hover {
    color: theme.$primary-color-hover;
  }
}

:local(.rooms-under-titles) {
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
  justify-content: flex-end;
  margin: 0 !important;
}
:local(.region-code) {
  opacity: 0.5;
  color: gray;
  margin: 30px 30px 20px 40px;
  font-size: 21px;
  @media(max-width: theme.$breakpoint-sm) {
    font-size: 16px;
  }
}
:local(.change-region) {
  margin: 30px 40px 20px 30px;
  color: gray;
  font-size: 21px;
  cursor: pointer;
  @media(max-width: theme.$breakpoint-sm) {
    font-size: 16px;
  }
  &:hover {
    color: theme.$primary-color-hover;
  }
}

:local(.rooms) {
  background-color: theme.$background2-color;
  border-radius: 16px;
}

:local(.row) {
  display: flex;
  justify-content: space-evenly !important;
}

:local(.col-lg) {
  flex-direction: column;

  @media(min-width: theme.$breakpoint-lg) {
    flex-direction: row;
  }
}

:local(.home-page) :local(.card) {
  background-color: theme.$background2-color;
  border-radius: 16px;
  font-weight: theme.$font-weight-medium;
  padding-bottom: 32px !important;
  flex: 1;
}

:local(.center-lg) {
  @media(max-width: theme.$breakpoint-lg) {
    text-align: center;
    align-items: center;
  }
}
